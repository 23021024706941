<template>
  <div class="pl-14">
    <v-card
      :collapse="!collapseOnScroll"
      :collapse-on-scroll="collapseOnScroll"
      absolute
      dark
      scroll-target="#scrolling-techniques-6"
      elevate-on-scroll
    >
      <v-card-title>
        <h1 class="font-weight-bold display-6 color-texto">Balance {{sucursal}}</h1>
      </v-card-title>
    </v-card>
    <v-sheet id="scrolling-techniques-6" class="overflow-y-auto" max-height="600">
      <v-container style="height: 60px;"></v-container>
    </v-sheet>
    <v-card
      class="mx-auto text-center"
      color="#6A76AB"
      dark
      max-width="1200"
    >
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="initialDate"
                  label="Fecha Inicial"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="initialDate"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                min="2020-01-01"
                locale="es-co"
                @change="save1"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="finalDate"
                  label="Fecha Final"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="finalDate"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                min="2020-01-01"
                locale="es-co"
                @change="save2"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols=2>
            <v-btn color="primary" dark class="mt-2" @click="iniciar">
              <v-icon>search</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-sheet color="rgba(0, 0, 0, .12)">
          <v-sparkline
            :value="items"
            color="rgba(255, 255, 255, .7)"
            height="60"
            padding="24"
            line-width="1"
            stroke-linecap="round"
            :label-size="labelSize"            
            smooth
          >
            <template v-slot:label="item">
              ${{ item.value }}
            </template>
          </v-sparkline>
        </v-sheet>
      </v-card-text>
      <v-card-text>
        <div class="text-h4 font-weight-thin">
          Ventas
        </div>
      </v-card-text>
    </v-card>
     <v-card
      class="mx-auto mt-6 text-center"
      color="purple lighten-1"
      dark
      max-width="1200"
    >
      <v-card-text>
        <v-sheet color="rgba(0, 0, 0, .12)">
          <v-sparkline
            :value="bondsItems"
            color="rgba(255, 255, 255, .7)"
            height="60"
            padding="24"
            line-width="1"
            stroke-linecap="round"
            :label-size="labelSize"            
            smooth
          >
            <template v-slot:label="item">
              ${{ item.value }}
            </template>
          </v-sparkline>
        </v-sheet>
      </v-card-text>
      <v-card-text>
        <div class="text-h4 font-weight-thin">
          Ganacias
        </div>
      </v-card-text>
    </v-card>
    <v-card
      class="mx-auto mt-6 text-center"
      color="green lighten-1"
      max-width="1200"
      dark
    >
      <v-card-text>
        <v-sheet color="rgba(0, 0, 0, .12)">
          <v-data-table
            :headers="headers"
            :items="registersItems"
            :loading="loading"
            :items-per-page="pages"
            hide-default-footer
            item-key="id"
            class="elevation-1"
            loading-text="Cargando..."
            >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Detalles</v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:no-data>
              Listado Detalles
            </template>
          </v-data-table>
        </v-sheet>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import { ApiBalance } from "./ApiBalance";

export default {
  name: "Balance",
  data() {
    return {
      api: new ApiBalance(),
      loading: false,
      collapseOnScroll: true,
      menu1: false,
      menu2: false,
      items: [],
      bondsItems: [],
      registersItems: [],
      pages:-1,
      labelSize:6,
      headers:[
        { text: 'Fecha', value:"date"},
				{ text: 'Ventas Servicios', value: 'total_sales_s', sortable: false},
				{ text: 'Ventas Productos', value: 'total_sales_p', sortable: false},
				{ text: 'Multas', value:"fines", sortable: false},
				{ text: 'Valor Productos', value: 'total_buy_price', sortable: false},
				{ text: 'Valor puntos', value: 'points', sortable: false},
				{ text: 'Gastos', value:"expenses", sortable: false},
				{ text: 'Pagos Nomina', value:"total_commission_pay", sortable: false},
				{ text: 'Nomina', value:"payroll", sortable: false},
				{ text: 'Neto', value: 'total', sortable: false},
				{ text: 'Efectivo', value: 'cash', sortable: false},
				{ text: 'Otros Medios', value: 'others', sortable: false},
      ],
      initialDate:(new Date(new Date().setDate(1) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      finalDate:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      sucursal: localStorage.getItem("Sucursal")
    };
  },
  methods: {
    async getSales(){
      try {
        this.loading = true;
        let params = `enterprise=${localStorage.getItem("SucursalId")}&i_date=${this.initialDate}&f_date=${this.finalDate}`
        let r = await this.api.getSalesP(params);
        this.items = r["sales"]; 
        if (1 <= this.items.length && this.items.length <= 7){
          this.labelSize = 6
        } else if (7 <= this.items.length && this.items.length <= 14){
          this.labelSize = 4
        } else {
          this.labelSize = 2
        }
      } catch (error) {
        // alert(error);
        // this.msgError(error)
        this.$swal("Error", error.toString())
      } finally {
        this.loading = false;
      }
    },
    async getBonds(){
      try {
        this.loading = true;
        let params = `enterprise=${localStorage.getItem("SucursalId")}&i_date=${this.initialDate}&f_date=${this.finalDate}`
        let r = await this.api.getBondsP(params);
        this.bondsItems = r["commissions"]; 
        if (1 <= this.bondsItems.length && this.bondsItems.length <= 7){
          this.labelSize = 6
        } else if (7 <= this.bondsItems.length && this.bondsItems.length <= 14){
          this.labelSize = 4
        } else {
          this.labelSize = 2
        }
      } catch (error) {
        // alert(error);
        // this.msgError(error)
        this.$swal("Error", error.toString())
      } finally {
        this.loading = false;
      }
    },
    async getRegisters(){
      try {
        this.loading = true;
        let params = `enterprise=${localStorage.getItem("SucursalId")}&i_date=${this.initialDate}&f_date=${this.finalDate}`
        let r = await this.api.getRegistersP(params);
        this.registersItems = r; 
      } catch (error) {
        // alert(error);
        // this.msgError(error)
        this.$swal("Error", error.toString())
      } finally {
        this.loading = false;
      }
    },
    async iniciar() {
      this.getSales()
      this.getBonds()
      this.getRegisters()
    },
    save1 (date) {
      this.$refs.menu1.save(date)
    },
    save2 (date) {
      this.$refs.menu2.save(date)
    },
  },
  created() {
    this.iniciar();
  },
};
</script>

<style scoped>
.color-texto {
    color: #356859 !important;
}
</style>
