import Api from "../Api"

export class ApiBalance extends Api{
    constructor(){
        super()
    }

    async getSalesP(params, id=-1){
        return await super.getParams("reportes/ventas", id, params)
    }

    async getBondsP(params, id=-1){
        return await super.getParams("reportes/ganancias", id, params)
    }

    async getRegistersP(params, id=-1){
        return await super.getParams("reportes/registros", id, params)
    }

}